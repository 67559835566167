import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    rootUrl :  "https://app.apiproxy.co/",//"http://104.155.59.91/liden_app/",//"https://app.apiproxy.co/","http://localhost:8010/proxy/"
    rootUrlAPP: "https://app.apiproxy.co/",
    accessToken : "xj91U3mjQUlIYZXofN",
    servicesKey: "xj91U3mjQUlIYZXofN",
    utm_source: "LIDEN"
  },
  mutations: {
    UPDATE_UTM(state, val){
      state.utm_source = val
      
      
    },
  },
  modules: {
    app,
    appConfig,
    verticalMenu,
  },
  strict: process.env.DEV,
})
