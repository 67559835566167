import Vue from 'vue'
import store from '@/store/index'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
   
    
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/dashboard/Analytics.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/inbox',
      name: 'apps-inbox',
      component: () => import('@/views/inbox/view.vue'),
    
    },
    {
      path: '/inbox/keywords:id',
      name: 'apps-inbox-keywords',
      component: () => import('@/views/inbox/Todo.vue'),
      props: true,
      meta: {
        contentRenderer: 'sidebar-left',
        contentClass: 'todo-application',
      },
    },
    {
      path: '/inbox/keywords/sidebar:filter',
      name: 'apps-inbox-keyowrds-filter',
      component: () => import('@/views/inbox/Todo.vue'),
      meta: {
        contentRenderer: 'sidebar-left',
        contentClass: 'todo-application',
      },
    },
    // *===============================================---*
    // *--------- Contacts -------------------------------*
    // *===============================================---*
    {
      path: '/contact',
      name: 'apps-contact',
      component: () => import('@/views/contacts/Todo.vue'),
      meta: {
        contentRenderer: 'sidebar-left',
        contentClass: 'todo-application',
      },
    },
    {
      path: '/contact:filter',
      name: 'apps-contact-filter',
      component: () => import('@/views/contacts/Todo.vue'),
      meta: {
        contentRenderer: 'sidebar-left',
        contentClass: 'todo-application',
      },
    },
    {
      path: '/bulk/rateCard',
      name: 'bulk-rate-card',
      component: () => import('@/views/rate/view-rateCard.vue'),
      
    },
    {
      path: '/survey/',
      name: 'survey-dashboard',
      component: () => import('@/views/survey/index.vue'),
      
    },
    {
      path: '/survey/view/:id',
      name: 'survey-view',
      component: () => import('@/views/survey/view.vue'),
      
    },
    {
      path: '/survey/create',
      name: 'survey-create',
      component: () => import('@/views/survey/create/index.vue'),
      
    },
    /**
     * View Blacklist Table
     */
     {
      path: '/blacklist/view',
      name: 'blacklist-view',
      component: () => import('@/views/blacklist/view-blacklist.vue'),
      
    },
    //
    // *===============================================---*
    // *--------- Messages & IT'S FILTERS N Tags -------------------------------*
    // *===============================================---*
    {
      path: '/message',
      name: 'bulk-message',
      component: () => import('@/views/bulk/Email.vue'),
      meta: {
        contentRenderer: 'sidebar-left',
        contentClass: 'email-application',
      },
    },
    {
      path: '/message/list',
      name: 'bulk-message-list',
      component: () => import('@/views/bulk/EmailList.vue'),
      meta: {
        pageTitle: 'Message',
      },
   
    },
    {
      path: '/message/:folder',
      name: 'bulk-message-folder',
      component: () => import('@/views/bulk/message.vue'),
      meta: {
        contentRenderer: 'sidebar-left',
        contentClass: 'email-application',
        navActiveLink: 'bulk-message',
      },
      beforeEnter(to, _, next) {
        if (['sent', 'Schedule', 'Pending'].includes(to.params.folder)) next()
        else next({ name: 'error-404' })
      },
    },
    {
      path: '/message/label/:label',
      name: 'bulk-message-label',
      component: () => import('@/views/bulk/message.vue'),
      meta: {
        contentRenderer: 'sidebar-left',
        contentClass: 'email-application',
        navActiveLink: 'bulk-message',
      },
      beforeEnter(to, _, next) {
        if (['personal', 'company', 'important', 'private'].includes(to.params.label)) next()
        else next({ name: 'error-404' })
      },
    },
    {
      path: '/inbox',
      name: 'inbox',
      component: () => import('@/views/bulk/inbox.vue'),
      meta: {
        pageTitle: 'View Inbox',
        breadcrumb: [
          {
            text: 'Apply Request',
            active: true,
          },
        ],
      },
    },
    {
      path: '/sms/analytic',
      name: 'sms-analytic',
      component: () => import('@/views/bulk/analytic.vue'),
      meta: {
        pageTitle: 'SMS Stats',
      },
  },
  {
    path: '/keyword',
    name: 'keyword',
    component: () => import('@/views/keywords/key.vue'),
    meta: {
      pageTitle: 'View Keywords',
      breadcrumb: [
        {
          text: 'Keywords provide a cost-efficient solution for your application to start receiving messages.',
          active: true,
        },
      ],
    },
},
{
  path: '/smpp',
  name: 'smpp',
  component: () => import('@/views/smpp/view.vue'),
  meta: {
    pageTitle: 'View SMPP',
    breadcrumb: [
      {
        text: 'SMPP Accounts',
        active: true,
      },
    ],
  },
},
{
  path: '/callback/delivery',
  name: 'callback-dlr',
  component: () => import('@/views/callback/delivery.vue'),
  meta: {
    pageTitle: 'Add Callback URL',
    breadcrumb: [
      {
        text: 'Add Callback url for Delivery',
        active: true,
      },
    ],
  },
},
{
  path: '/callback/incoming',
  name: 'callback-incoming',
  component: () => import('@/views/callback/incoming.vue'),
  meta: {
    pageTitle: 'Add Callback URL',
    breadcrumb: [
      {
        text: 'Add Callback url for Incoming Messages',
        active: true,
      },
    ],
  },
},
{
  path: '/callback/subscription',
  name: 'callback-subscription',
  component: () => import('@/views/callback/subscription.vue'),
  meta: {
    pageTitle: 'Add Callback URL',
    breadcrumb: [
      {
        text: 'Add Callback url for subscription Messages',
        active: true,
      },
    ],
  },
},

{
  path: '/premium/analytic',
  name: 'premium-analytic',
  component: () => import('@/views/premium/analytic.vue'),
  meta: {
    pageTitle: 'View Premium Stats',
    breadcrumb: [
      {
        text: 'View Premium Stats',
        active: true,
      },
    ],
  },
},
{
  path: '/premium/product',
  name: 'premium-product',
  component: () => import('@/views/premium/product.vue'),
  meta: {
    pageTitle: 'View Premium Product',
    breadcrumb: [
      {
        text: 'View Premium Product',
        active: true,
      },
    ],
  },
},
{
  path: '/premium/outbox',
  name: 'premium-outbox',
  component: () => import('@/views/premium/outbox.vue'),
  meta: {
    pageTitle: 'View Premium Outbox',
    breadcrumb: [
      {
        text: 'View Premium Outbox',
        active: true,
      },
    ],
  },
},
{
  path: '/alphanumerics',
  name: 'alphanumerics',
  component: () => import('@/views/alphanumerics/view-senderID.vue'),
  meta: {
    pageTitle: 'View Alphanumerics',
    breadcrumb: [
      {
        text: 'Sender Ids',
        active: true,
      },
    ],
  },
},
{
  path: '/shortcode',
  name: 'shortcode',
  component: () => import('@/views/shortcode/view.vue'),
  meta: {
    pageTitle: 'View Shortcode',
    breadcrumb: [
      {
        text: 'Shortcode',
        active: true,
      },
    ],
  },
},
{
  path: '/ussd/analytics',
  name: 'analytics-ussd',
  component: () => import('@/views/ussd/analytics.vue'),
  meta: {
    pageTitle: 'View USSD Analytics',
    breadcrumb: [
      {
        text: 'Anaytics',
        active: true,
      },
    ],
  },
},
{
  path: '/ussd/session',
  name: 'session-ussd',
  component: () => import('@/views/ussd/session.vue'),
  meta: {
    pageTitle: 'View USSD Session',
    breadcrumb: [
      {
        text: 'Session',
        active: true,
      },
    ],
  },
},
{
  path: '/ussd/code',
  name: 'code-ussd',
  component: () => import('@/views/ussd/services-code.vue'),
  meta: {
    pageTitle: 'View USSD Services Code',
    breadcrumb: [
      {
        text: 'Codes',
        active: true,
      },
    ],
  },
},
{
  path: '/airtime/analytics',
  name: 'analytics-airtime',
  component: () => import('@/views/airtime/analytics.vue'),
  meta: {
    pageTitle: 'View Airtime Analytics',
    breadcrumb: [
      {
        text: 'Airtime Stats',
        active: true,
      },
    ],
  },
},
{
  path: '/airtime/transactions',
  name: 'airtime-transaction',
  component: () => import('@/views/airtime/transactions.vue'),
  meta: {
    pageTitle: 'View Airtime Transactions',
    breadcrumb: [
      {
        text: 'Airtime',
        active: true,
      },
    ],
  },
},
{
  path: '/payment/analytics',
  name: 'analytics-payment',
  component: () => import('@/views/payments/analytics.vue'),
  meta: {
    pageTitle: 'View Payments Analytics',
    breadcrumb: [
      {
        text: 'C2B & B2C Stats',
        active: true,
      },
    ],
  },
},
{
  path: '/payment/products',
  name: 'payments-products',
  component: () => import('@/views/payments/products.vue'),
  meta: {
    pageTitle: 'View Payments Products',
    breadcrumb: [
      {
        text: 'Paybill / Tills',
        active: true,
      },
    ],
  },
},
{
  path: '/payment/transactions',
  name: 'payments-transactions',
  component: () => import('@/views/payments/transactions.vue'),
  meta: {
    pageTitle: 'View Payments Transactions',
    breadcrumb: [
      {
        text: 'transactions',
        active: true,
      },
    ],
  },
},
{
  path: '/keyword/apply',
  name: 'keyword-apply',
  component: () => import('@/views/keywords/apply.vue'),
  meta: {
    pageTitle: 'Apply Keywords',
    breadcrumb: [
      {
        text: 'Keywords provide a cost-efficient solution for your application to start receiving messages.',
        active: true,
      },
    ],
  },
},
    // *===============================================---*
    // *--------- Messages & IT'S FILTERS N Tags -------------------------------*
    // *===============================================---*
    {
      path: '/request',
      name: 'request',
      component: () => import('@/views/request/applyRequest.vue'),
      meta: {
        pageTitle: 'Apply Request',
        breadcrumb: [
          {
            text: 'Apply Request',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users/list',
      name: 'apps-users-list',
      component: () => import('@/views/user/users-list/UsersList.vue'),
    },
    {
      path: '/users/view/:id',
      name: 'apps-users-view',
      component: () => import('@/views/user/users-view/UsersView.vue'),
    },
    {
      path: '/users/edit/:id',
      name: 'apps-users-edit',
      component: () => import('@/views/user/users-edit/UsersEdit.vue'),
    },

    {
      path: '/invoice/list',
      name: 'apps-invoice-list',
      component: () => import('@/views/invoice/invoice-list/InvoiceList.vue'),
    },
    {
      path: '/invoice/preview/:id',
      name: 'apps-invoice-preview',
      component: () => import('@/views/invoice/invoice-preview/InvoicePreview.vue'),
    },
    {
      path: '/invoice/add/',
      name: 'apps-invoice-add',
      component: () => import('@/views/invoice/invoice-add/InvoiceAdd.vue'),
    },
    {
      path: '/invoice/edit/:id',
      name: 'apps-invoice-edit',
      component: () => import('@/views/invoice/invoice-edit/InvoiceEdit.vue'),
    },
    {
      path: '/profile/account-settings',
      name: 'account-setting',
      component: () => import('@/views/profile/AccountSetting.vue'),
    },
    {
      path: '/view/request',
      name: 'view-request',
      component: () => import('@/views/request/viewApplication.vue'),
      meta: {
        pageTitle: 'View Request',
        breadcrumb: [
          {
            text: 'View Request',
            active: true,
          },
        ],
      },
    },
    {
      path: '/developer/balance/alert',
      name: 'balance-alert',
      component: () => import('@/views/developer/balance-alert.vue'),
      meta: {
        pageTitle: 'Balance Alert',
        breadcrumb: [
          {
            text: 'Balance Alert',
            active: true,
          },
        ],
      },
    },
    {
      path: '/developer/api',
      name: 'dev-api',
      component: () => import('@/views/developer/api.vue'),
      meta: {
        pageTitle: 'Generate API Key',
        breadcrumb: [
          {
            text: 'An API Key helps us authenticate requests that you make to our APIs.',
            active: true,
          },
        ],
      },
    },
    
    {
      path: '/billing/tops',
      name: 'billing-tops',
      component: () => import('@/views/billing/tops.vue'),
    },
    {
      path: '/billing/payment-methods',
      name: 'billing-payment-methods',
      component: () => import('@/views/billing/payment-method.vue'),
    },
    {
      path: '/billing/expenditure',
      name: 'billing-expenditure',
      component: () => import('@/views/billing/expenditure.vue'),
    },
    {
      path: '/billing/refunded',
      name: 'billing-refund',
      component: () => import('@/views/billing/refund.vue'),
    },

    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/signup',
      name: 'signup',
      component: () => import('@/views/SignUp.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/reset',
      name: 'reset',
      component: () => import('@/views/Resetpwd.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/verification',
      name: 'verification',
      component: () => import('@/views/verification.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})
router.beforeEach((to, from, next) => {
  console.log(to.query)
  
  if(to.query.utm_source){
    console.log(to.query.utm_source)
    localStorage.setItem('utm_source', to.query.utm_source)
  }
  
  next();
})

export default router
